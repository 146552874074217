import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';

import Inperson from '../../pics/fulltime_course_barcelona.jpg'

const JSBEWSP = () => (

	<div className="white grid-1-3"
	>
	<Helmet

	title="Web / Mobile Development Bootcamp payments page"
	meta={[
		{ name: 'description', content: 'BCS Payments page' },
		{ name: 'keywords', content: 'payment form' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" }
			]}
			/>
			<div>
			<h1>Web / Mobile Development Bootcamp in Barcelona Code School</h1>
			<h2>Payment form: Edie Windsor Scholarship 50% off</h2>
			</div>

			<div style={{
				marginTop: '2em',

			}}>
			<img 
			className='banners'
			src={Inperson} 
			alt='Web / Mobile Development Bootcamp in Barcelona Code School'/>

			</div>



			

			<h2 id="cohorts">Access</h2>
			<div>
			<div className="event" >
			<h4><strike>5800€</strike> 2900€</h4>
			<span>
			<h2>Tuition payment</h2>
			<p>Deposit of 580€ is paid</p>
			<p>Remaining tuition due: 2320€</p>
			</span>
			<span>
			<p>Edie Windsor Scholarship applied</p>
			<Link to="/register" state={{course: "Web / Mobile Development Bootcamp w/ 50% off and deposit paid", date:'According to contract', cost:2320}}>
			<button>Proceed to checkout</button>
			</Link>
			</span>
			</div>
			



			
			</div>




			</div>


			)

export default JSBEWSP
